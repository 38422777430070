import moment from "moment-timezone";

// Automatically detect the user's timezone
export const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Convert UTC time to user's local time
export const convertToLocalTime = (utcTime) => {
  return moment(utcTime).tz(userTimeZone).format("YYYY-MM-DD HH:mm:ss");
};

// Convert local time to UTC
export const convertToUtc = (localTime) => {
  const localMoment = moment.tz(
    localTime,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  return localMoment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
};

export const convertToUtc2 = (localTime) => {
  return moment(localTime).utc().format("YYYY-MM-DD HH:mm:ss");
};

import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FaCircleXmark, FaPlus, FaTrash } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import { Button, Input } from "../CustomComponents";
import styles from "../CustomComponents/index.module.css";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editMcqModal.module.scss";

const EditWheelOptionModal = ({
  handleEditModalClose,
  data,
  handleAdd,
  handleUpdate,
  updateLoading,
  shopifyProduct,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      options: [
        {
          option: "",
          discount_type: "percentage",
          product_id: "",
          product_title: "",
          product_image: "",
          product_price: "",
        },
      ],
    },
  });

  const { fields, prepend, insert, remove, update, append } = useFieldArray({
    control,
    name: "options",
    defaultValues: [
      {
        option: "",
        discount_type: "percentage",
        product_id: "",
        product_title: "",
        product_image: "",
        product_price: "",
      },
    ],
  });

  const [flag, setFlag] = useState(false);

  const DISCOUNT_TYPE = [
    { label: "Percentage", value: "percentage" },
    { label: "Fixed Amount", value: "fixed_amount" },
  ];

  useEffect(() => {
    if (data) {
      setValue("options[0]", {
        option: Number(data?.option),
        discount_type: data?.discount_type,
        product_id: data?.product_id,
        product_title: data?.product_title,
        product_image: data?.product_image,
        product_price: data?.product_price,
        id: data?.id,
      });
      setFlag(!flag);
    }
  }, [data]);

  const onSubmit = async (formData) => {
    try {
      const options = formData?.options;

      for (let i = 0; i < options.length; i++) {
        const { option, discount_type, product_price, product_id } = options[i];

        let inputRef = document.getElementById(`option_${i}`);

        if (!!product_id && discount_type === "fixed_amount") {
          if (Number(option) > Number(product_price)) {
            inputRef.focus();

            toast.error(
              `Discount amount must be less than product price ${product_price}`
            );
            return;
          }
        } else if (discount_type === "percentage") {
          let condition_1 =
            calculatePercentage(Number(product_price), Number(option)) >
            Number(product_price);
          let condition_2 = Number(option) > 100;

          if (condition_1) {
            inputRef.focus();
            toast.error(
              `Percentage must be less than product price ${product_price}`
            );
            return;
          } else if (condition_2) {
            inputRef.focus();
            toast.error(`Percentage must be less than 100%`);
            return;
          }
        }
      }

      if (data) {
        handleUpdate(options);
      } else {
        handleAdd(options);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const calculatePercentage = (amount, percentage) => {
    return (amount * percentage) / 100;
  };

  return (
    <ModalContainer handleClose={handleEditModalClose}>
      <div className={cssStyles.modalContainer}>
        <div className="d-flex align-items-center w-100 justify-content-between mb-4">
          <p className="fs-4 fw-medium m-0 black-text">
            {data ? "Edit Spin Wheel" : "Add Spin Wheel"}
          </p>
          <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
            <FaCircleXmark
              role="button"
              onClick={handleEditModalClose}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
        <div className="w-100">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gy-3">
              {fields.map((field, index) => (
                <React.Fragment key={index}>
                  <Col sm={12} md={6}>
                    <Row className="gy-3">
                      <Col sm={6}>
                        <p className="m-0 black-text">Discount type</p>

                        <Controller
                          control={control}
                          name={`options.${index}.discount_type`}
                          rules={{}}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <select
                              onChange={(e) => {
                                onChange(e);
                                setFlag(!flag);
                              }}
                              className={`${styles.Input} w-100`}
                            >
                              {DISCOUNT_TYPE.map((item, index) => (
                                <option key={index} value={item.value || ""}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          )}
                        />

                        {!!errors?.options?.length && (
                          <p className="m-0 text-danger text-center">
                            {errors?.options[index]?.option?.discount_type}
                          </p>
                        )}
                      </Col>

                      <Col sm={6}>
                        <p className="m-0 black-text">Product List</p>
                        <Controller
                          control={control}
                          name={`options.${index}.product_id`}
                          rules={{}}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Select
                              value={getValues(`options.${index}.product_id`)}
                              onChange={(e) => {
                                onChange(e);
                                let product =
                                  shopifyProduct?.data?.products.find(
                                    (item) => {
                                      if (e.target.value === item.id)
                                        return item;
                                    }
                                  );
                                setValue(
                                  `options.${index}.product_title`,
                                  product?.title
                                );
                                setValue(
                                  `options.${index}.product_image`,
                                  product?.images[0]?.src
                                );
                                setValue(
                                  `options.${index}.product_price`,
                                  product?.variants[0]?.price
                                );
                                setFlag(!flag);
                              }}
                              className={`${styles.Input} w-100`}
                              style={{ height: "50px" }}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    maxHeight: "50%",
                                    width: "10%",
                                    borderRadius: 5,
                                  },
                                },
                              }}
                              renderValue={(value) => {
                                let product =
                                  shopifyProduct?.data?.products.find(
                                    (item) => {
                                      if (value == item?.id) {
                                        return item;
                                      }
                                    }
                                  );
                                return (
                                  <div className="d-flex align-items-center gap-2">
                                    <img
                                      src={product?.images[0]?.src}
                                      alt="Image not found"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "10px",
                                      }}
                                    />

                                    <div>{product?.title}</div>
                                  </div>
                                );
                              }}
                            >
                              <MenuItem value={""}>
                                <div className="text-wrap text-secondary text-center w-100 my-3">
                                  Select Option
                                </div>
                              </MenuItem>
                              {!!shopifyProduct?.data?.products?.length &&
                                shopifyProduct?.data?.products.map(
                                  (item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                      <div className="d-flex align-items-center gap-2">
                                        <img
                                          src={item?.images[0]?.src}
                                          alt="Image not found"
                                          style={{
                                            width: "70px",
                                            height: "70px",
                                            borderRadius: "19px",
                                          }}
                                        />

                                        <div className="text-wrap text-secondary">
                                          {item.title}
                                        </div>
                                      </div>
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={12} md={6}>
                    <p className="m-0 black-text">
                      {getValues(`options.${index}.discount_type`) ===
                        "percentage" && (
                        <div>Write Discount in Percentage </div>
                      )}

                      {getValues(`options.${index}.discount_type`) ===
                        "fixed_amount" && <div> Write Discount on Amount</div>}
                    </p>
                    <React.Fragment>
                      <Controller
                        control={control}
                        name={`options.${index}.option`}
                        rules={{ required: "Option is required." }}
                        render={({ field: { onChange, value } }) => (
                          <InputGroup className="d-flex justify-content-between align-items-center flex-row gap-3">
                            <Input
                              type="number"
                              placeholder={`Enter option ${index + 1}`}
                              value={getValues(`options.${index}.option`)}
                              onChange={(e) => {
                                onChange(e);
                                setFlag(!flag);
                              }}
                              id={`option_${index}`}
                            />

                            {fields.length - 1 !== index ? (
                              <Button
                                type="text"
                                className="btn px-3 red-text input-after-icon rounded-5 border-0"
                                text={<FaTrash />}
                                onClick={() => remove(index)}
                              />
                            ) : (
                              <Button
                                classes="btn px-3 black-text input-before-icon rounded-5 border-0 green-text"
                                text={<FaPlus />}
                                onClick={() =>
                                  append({
                                    option: "",
                                    discount_type: "percentage",
                                    product_id: "",
                                    product_title: "",
                                    product_image: "",
                                    product_price: "",
                                  })
                                }
                              />
                            )}
                          </InputGroup>
                        )}
                      />
                      {/* 
                      {getValues(`options.${index}.discount_type`) ===
                        "fixed_amount" && (
                        <div className="text-danger">
                          {Number(getValues(`options.${index}.option`)) >
                            Number(
                              getValues(`options.${index}.product_price`)
                            ) && (
                            <div>
                              Amount must be greater than product price{" "}
                              {Number(
                                getValues(`options.${index}.product_price`)
                              )}
                            </div>
                          )}
                        </div>
                      )} */}

                      {/* {getValues(`options.${index}.discount_type`) ===
                        "percentage" && (
                        <div className="text-danger">
                          {calculatePercentage(
                            Number(getValues(`options.${index}.product_price`)),
                            Number(getValues(`options.${index}.option`))
                          ) >
                            Number(
                              getValues(`options.${index}.product_price`)
                            ) && (
                            <div>
                              Percentage must be less than product price{" "}
                              {getValues(`options.${index}.product_price`)}
                            </div>
                          )}
                        </div>
                      )} */}

                      {!!errors?.options?.length && (
                        <p className="m-0 text-danger text-center">
                          {errors?.options[index]?.option?.message}
                        </p>
                      )}
                    </React.Fragment>
                  </Col>
                </React.Fragment>
              ))}

              <Col md={12} sm={12}>
                <Button
                  classes={`${cssStyles.cardBtn} mt-4 ${
                    updateLoading ? "bg-dark-subtle" : ""
                  }`}
                  text={
                    updateLoading ? (
                      <BeatLoader size={10} />
                    ) : data ? (
                      "Update"
                    ) : (
                      "Save"
                    )
                  }
                  type="Submit"
                ></Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </ModalContainer>
  );
};

export default EditWheelOptionModal;

import React, { useMemo } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

// global scss for globally styles and fonts
import "./_fonts.scss";
import "./_global.scss";

import PublicRoute from "./Components/Routes/PublicRoute.js";
import { WEB_ROUTES } from "./constants.js";
import { baseRoute } from "./Helper";

import { uniqueId } from "lodash";
import ProtectedLayout from "./Components/Layouts/ProtectedLayout.js";
import ProtectedRoute from "./Components/Routes/ProtectedRoute.js";
import Account from "./Pages/Account";
import Dashboard from "./Pages/Dashboard";
import McqManagementScreen from "./Pages/McqManagement/McqManagementScreen.js";
import PriceGuessingManagementScreen from "./Pages/PriceGuessingManagement/PriceGuessingManagementScreen.js";
import PromoCodesManagementScreen from "./Pages/PromoCodesManagement/PromoCodesManagementScreen.js";
import SessionManagementScreen from "./Pages/SessionManagement/SessionManagementScreen.js";
import SpinWheelOption from "./Pages/SpinWheelOption/SpinWheelOption.js";

function App() {
  const ROUTES = useMemo(() => {
    return [
      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.ACCOUNT}
        element={
          <PublicRoute>
            <Account />
          </PublicRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.DASHBOARD}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={0}>
              <Dashboard />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.PRICE_GUESSING}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={1}>
              <PriceGuessingManagementScreen />
              {/* <Dashboard /> */}
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.MCQS}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={2}>
              <McqManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.SPIN_WHEEL_OPTIONS}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={3}>
              <SpinWheelOption />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.SESSION}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={4}>
              <SessionManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.PROMO_CODE}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={5}>
              <PromoCodesManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
    ];
  }, []);

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route path={"*"} element={<Navigate to={WEB_ROUTES.ACCOUNT} />} />
      </Routes>
    </Router>
  );
}

export default App;

// const BASE_URL = "http://localhost:3001";
const BASE_URL = "https://api.playluckyduck.trangotechdevs.com/"; // staging
// const BASE_URL = "http://api.playluckyduck.trangotechdevs.com:3034/"; // staging with port
// const BASE_URL = "https://api.playluckyduck.com/"; // client server
// const BASE_URL = "http://localhost:3034/"; // client server

const API_URL = BASE_URL + "api/admin/";

const INITIAL_TOKEN = "miyqggucnmhijwlbweoanucplvxmezcq";

export { API_URL, BASE_URL, INITIAL_TOKEN };

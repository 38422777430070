import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaUserEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { Button, SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Table from "../../Components/Table/Table";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/mcq_management.module.scss";

import { useSelector } from "react-redux";
import { ShimmerTable } from "react-shimmer-effects";
import EditMcqModal from "../../Components/Modals/EditMcqModal";
import {
  useAddQuestionMutation,
  useDeleteQuestionMutation,
  useGetAllMcqQuery,
  useUpdateQuestionMutation,
} from "../../Data/services/questionsApi";

const McqManagementScreen = ({ setPage }) => {
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { data, isSuccess, isLoading, isFetching, refetch } = useGetAllMcqQuery(
    { refetchOnMountOrArgChange: true }
  );

  var mcqData = useSelector((state) => state.questions.mcq);

  useEffect(() => {
    setRowData(mcqData);
  }, [mcqData]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setEditModal(true);
  };

  const [deleteQuestion, { isLoading: isDeleting }] =
    useDeleteQuestionMutation();

  const deleteData = async () => {
    const id = editedData.id;

    try {
      const { data } = await deleteQuestion(id);
      // console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const [addQuestion, { isLoading: addLoading }] = useAddQuestionMutation();

  const handleAdd = async (data) => {
    try {
      const formData = new FormData();
      formData.append("module_type", data.module_type);
      formData.append("question", data.question);
      formData.append("description", data.description);
      formData.append("time_limit", Number(data.time_limit));
      formData.append("status", data.status);

      data.options.forEach((option, index) => {
        formData.append(`options[${index}][option]`, option.option);
        formData.append(`options[${index}][is_correct]`, option.is_correct);
      });

      // Log the FormData entries to see what is being sent
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}:`, value);
      // }

      const response = await addQuestion(formData);

      if (response?.error?.data?.code == 400) {
        Toast(response?.error?.data?.message, "error");
        return;
      }

      if (response?.data?.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(response.data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const [updateQuestion, { isLoading: updateLoading }] =
    useUpdateQuestionMutation();

  const handleUpdate = async (data) => {
    const formData = new FormData();
    formData.append("module_type", data.module_type);
    formData.append("question", data.question);
    formData.append("description", data.description);
    formData.append("time_limit", Number(data.time_limit));
    formData.append("status", data.status);

    data.options.forEach((option, index) => {
      formData.append(`options[${index}][option]`, option.option);
      formData.append(`options[${index}][is_correct]`, option.is_correct);
    });

    // console.log(formData);
    // Log the FormData entries to see what is being sent
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }

    try {
      const response = await updateQuestion({
        modifiedData: formData,
        id: editedData.id,
      });

      if (response?.error?.data?.code == 400) {
        Toast(response?.error?.data?.message, "error");
        return;
      }

      if (response.data.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(response.data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  function indication() {
    return <ShimmerTable row={5} col={4} />;
  }

  const columns = [
    {
      dataField: "question",
      text: "Questions",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "question_options",
      text: "Options",
      sort: true,
      headerStyle: {
        width: "300px",
      },
      formatter: (cellContent, row) => {
        if (row.id && row.question_options) {
          return row.question_options.map((option, index) => {
            return (
              <div
                className={`${styles.option} ${
                  option.is_correct ? styles.greenBg : ""
                }`}
                key={index}
              >
                {option.option}
              </div>
            );
          });
        }
      },
    },
    {
      dataField: "time_limit",
      text: "Time Limit",
      sort: true,
      align: "center",

      headerAlign: "center",
      headerStyle: {
        width: "100px",
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "80px",
      },
      formatter: (cellContent, row) => {
        if (row.status === "active") {
          return <div className={styles.active}> Active </div>;
        } else {
          return <div className={styles.block}>InActive</div>;
        }
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center black-text rounded-3 mx-2 p-2 `}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleEditModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1 `}
                >
                  <FaUserEdit />
                </span>
                <span>Edit</span>
              </div>
              <div
                className="d-flex justify-content-center align-items-center bg-danger white-text rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal && (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          isDeleting={isDeleting}
        />
      )}

      {editModal && (
        <EditMcqModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          handleAdd={handleAdd}
          handleUpdate={handleUpdate}
          updateLoading={updateLoading}
        />
      )}

      <div className={styles.headerContainer}>
        <SubTitle text={"MCQ's Management"} classes="black-text" />

        <Row>
          <Col md={12}>
            <Button
              classes={`${styles.cardBtn} fs-6 px-4`}
              text="Add MCQ"
              onClick={handleAddUser}
            ></Button>
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        <Table data={rowData || []} columns={columns} indication={indication} />
      </div>
    </div>
  );
};

export default McqManagementScreen;
